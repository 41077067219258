<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>
            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='name'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`name.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.name[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='description'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`description.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model='form.description[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>


            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.image')"
                rules='required'
              >
                <ImagePreview
                  v-model='image'
                  :label="$t('labels.image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>


<!--            <b-col-->
<!--              md='6'-->
<!--              cols='12'-->
<!--            >-->
<!--              <validation-provider-->
<!--                v-slot='{ errors }'-->
<!--                :name="$t('sort')"-->
<!--                vid='sort'-->
<!--                :rules='{-->
<!--                         numeric: true,-->
<!--                       }'-->
<!--              >-->
<!--                <b-form-group>-->
<!--                  <template #label>-->
<!--                    <span>{{ $t('sort') }}</span>-->
<!--                  </template>-->
<!--                  <b-form-input-->
<!--                    v-model='form.sort'-->
<!--                    :placeholder="$t('sort')"-->
<!--                    autocomplete='off'-->
<!--                    type='number'-->
<!--                  />-->
<!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->


            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import _ from 'lodash'

export default {
  mixins: [formMixin],
  data() {
    return {
      image: null,
      form: {
        name: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        // sort: null,
         image: null,
      },
      isDataLoaded: false,
    }
  },
  watch: {

    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  computed: {
    toRedirectResource() {
      return 'service'
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            description: data?.description['ar'] ?? false ? data.description : { en: null, ar: null },
            status: data.status,
            sort: data.sort,
          }
          this.image = data.image_path
          this.isDataLoaded = true
        })
    },

    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {

        if (!($form.image instanceof File)) {
          delete $form.image
        }

        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },

  },
}
</script>
